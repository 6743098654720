import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";
import { plugin as pluginNetwork } from "@appsignal/plugin-breadcrumbs-network";
import { plugin as pluginPath } from "@appsignal/plugin-path-decorator";
import React from "react";
import { buildEnv } from "./buildEnv";

const getReleaseStage = () => {
  switch (location.hostname) {
    case "app.sugb.ch":
      return "production";
    case "app-test.sugb.ch":
      return "staging";
    default:
      return "dev";
  }
};

export const appsignal = new Appsignal({
  key:
    // These keys are not secrets, as they have to end up on the frontend anyway.
    getReleaseStage() === "production"
      ? "545eb47b-3cf7-4d0d-ad5e-0850a3c2d7db"
      : getReleaseStage() === "staging"
        ? "1656edbc-0705-4855-a54d-ccdd1e1fe457"
        : "df861164-caf8-4d15-b567-ac5ea8503dc7",

  revision: buildEnv.appRevision,
});

appsignal.use(pluginNetwork({ fetchEnabled: true, xhrEnabled: true }));
appsignal.use(pluginPath({}));

// Lets keep this line to know what version is running
console.log("APP_REVISION", buildEnv.appRevision);

const FallbackComponent = ({ error }: any) => {
  // we also print to console for easier debugging
  console.error(error);
  return <div className="whitespace-pre">{JSON.stringify(error)}</div>;
};

export const ErrorHandler = ({ children }: any) => (
  <ErrorBoundary
    instance={appsignal}
    fallback={(error: any) => <FallbackComponent error={error} />}
  >
    {children}
  </ErrorBoundary>
);
